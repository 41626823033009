import { Currency, Pair, Token } from '@abstra-dex/sdk'
import { Button, ChevronDownIcon, Text, useModal, Flex, Box, MetamaskIcon } from 'packages/uikit'
import styled from 'styled-components'
import { registerToken } from 'utils/wallet'
import { isAddress } from 'utils'
import { useTranslation } from 'contexts/Localization'
import { WrappedTokenInfo } from 'state/types'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { FlexGap } from 'components/Layout/Flex'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import { CurrencyLogo, DoubleCurrencyLogo } from '../Logo'

import { Input as NumericalInput } from './NumericalInput'
import { CopyButton } from '../CopyButton'

const InputRow = styled.div<{ selected: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  padding: ${({ selected }) => (selected ? '0.75rem 0.5rem 0.75rem 1rem' : '0.75rem 0.75rem 0.75rem 1rem')};
`
const CurrencySelectButton = styled(Button).attrs({ variant: 'text', scale: 'sm' })`
  padding: 0 0.5rem;
`
const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.75rem;
  line-height: 1rem;
`
export const InputPanel = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  z-index: 1;
`
const Container = styled.div`
  border-radius: 16px;
  box-shadow: ${({ theme }) => theme.shadows.inset};
`

const ButtonCurrency = styled.div`
  display: flex;
  align-items: center;
  gap: 0;
  padding: 2px 4px;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 7px 0px 10px 0px;
    border: 0;
    gap: 8px;
  }
`

const PositionButton = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  color: rgba(255, 255, 255, 0.8);
  transform: translate(-50%, -100%);
  background: transparent;
`

const MaxButton = styled.button`
  background: transparent;
  outline: none;
  border: unset;
  color: ${({ theme }) => theme.colors.absWhiteColor};
  font-size: 16px;
  font-weight: 400;
`

const PositionButtonSelect = styled(PositionButton)`
  transform: translate(-12px, calc(-100% + 4px));
`

const SelectToken = styled(CurrencySelectButton)`
  color: ${({ theme }) => theme.colors.absWhiteColor};
  border-radius: 25px;
  border: 1px solid ${({ theme }) => theme.colors.absWhiteColor};
  font-weight: 400;
`

const StyledBalance = styled(Text)`
  max-width: 125px;
  word-break: break-all;
`

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  showCurrency?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
  placeholder?: string
  selectToken?: boolean
}
export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  showCurrency = true,
  hideBalance = false,
  pair = null, // used for double token logo
  otherCurrency,
  id,
  showCommonBases,
  placeholder = '0.00',
  selectToken = false,
  ...props
}: CurrencyInputPanelProps) {
  const { account, library } = useActiveWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const {
    t,
    currentLanguage: { locale },
  } = useTranslation()

  const token = pair ? pair.liquidityToken : currency instanceof Token ? currency : null
  const tokenAddress = token ? isAddress(token.address) : null

  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={currency}
      otherSelectedCurrency={otherCurrency}
      showCommonBases={showCommonBases}
    />,
  )
  return (
    <Box position="relative" id={id} {...props}>
      <Flex mb="6px" alignItems="center" justifyContent="space-between">
        <FlexGap gap="4px">
          {showCurrency && (
            <CurrencySelectButton
              className="open-currency-select-button"
              selected={!!currency}
              onClick={() => {
                if (!disableCurrencySelect) {
                  onPresentCurrencyModal()
                }
              }}
            >
              <ButtonCurrency alignItems="center" justifyContent="space-between">
                {pair ? (
                  <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
                ) : currency ? (
                  <CurrencyLogo currency={currency} size="24px" style={{ marginRight: '8px' }} />
                ) : null}
                {pair ? (
                  <Text fontSize="clamp(14px,2vw,16px)" color="absMainColor" id="pair" bold>
                    {pair?.token0.symbol}:{pair?.token1.symbol}
                  </Text>
                ) : (
                  <Text fontSize="clamp(14px,2vw,16px)" color="absMainColor" id="pair" bold>
                    {(currency && currency.symbol && currency.symbol.length > 20
                      ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                          currency.symbol.length - 5,
                          currency.symbol.length,
                        )}`
                      : currency?.symbol) || t('Select a currency')}
                  </Text>
                )}
                {!disableCurrencySelect && <ChevronDownIcon color="absBlackColor" />}
              </ButtonCurrency>
            </CurrencySelectButton>
          )}
          {token && tokenAddress && showCurrency ? (
            <Flex style={{ gap: '4px' }} alignItems="center">
              <CopyButton
                width="16px"
                buttonColor="absBlackColor"
                text={tokenAddress}
                tooltipMessage={t('Token address copied')}
                tooltipTop={-20}
                tooltipRight={40}
                tooltipFontSize={12}
              />
              {library?.provider?.isMetaMask && (
                <MetamaskIcon
                  style={{ cursor: 'pointer' }}
                  width="16px"
                  onClick={() =>
                    registerToken(
                      tokenAddress,
                      token.symbol,
                      token.decimals,
                      token instanceof WrappedTokenInfo ? token.logoURI : undefined,
                    )
                  }
                />
              )}
            </Flex>
          ) : null}
        </FlexGap>
        {account && (
          <Text onClick={onMax} color="absBlackColor" fontSize="12px" style={{ display: 'inline', cursor: 'pointer' }}>
            {!hideBalance && !!currency ? (
              <FlexGap flexDirection="column" gap="0">
                <Text color="absBlackColor" fontSize="12px" ml="auto">
                  {t('Balance:')}
                </Text>
                <StyledBalance color="absBlackColor" fontSize="12px" ml="auto">
                  {selectedCurrencyBalance?.toFixed(2) ?? t('Loading')}
                </StyledBalance>
              </FlexGap>
            ) : (
              ''
            )}
          </Text>
        )}
      </Flex>
      <InputPanel>
        <Container as="label">
          <LabelRow>
            <NumericalInput
              className="token-amount-input"
              value={value}
              placeholder={placeholder}
              onUserInput={(val) => {
                onUserInput(val)
              }}
            />
          </LabelRow>
          <InputRow selected={disableCurrencySelect}>
            {account && currency && showMaxButton && label !== 'To' && (
              <PositionButton>
                <MaxButton onClick={onMax} scale="xs">
                  {t('Max')}
                </MaxButton>
              </PositionButton>
            )}

            {selectToken ? (
              <PositionButtonSelect>
                <SelectToken onClick={onPresentCurrencyModal}>
                  <img src="/images/chain/btc-icon.svg" alt="token" />
                  <Text color="text" ml="4px">
                    BTC
                  </Text>
                  <ChevronDownIcon color="text" />
                </SelectToken>
              </PositionButtonSelect>
            ) : null}
          </InputRow>
        </Container>
      </InputPanel>
    </Box>
  )
}
